import React, { Component } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { fetchContent } from '../../store/actions';
import apiRoutes from '../../api/routes';

import Home from '../Pages/Home/Home';
import Placeholder from '../UI/Placeholder';
import NotFound from '../Pages/NotFound/NotFound';

const About = loadable(() => import('../Pages/About/About'));
const Awards = loadable(() => import('../Pages/Awards/Awards'));
const Contact = loadable(() => import('../Pages/Contact/Contact'));
const Studio = loadable(() => import('../Pages/Studio/Studio'));
const CareersLanding = loadable(() => import('../Pages/CareersLanding/CareersLanding'));
const CareersPosition = loadable(() => import('../Pages/CareersPositions/CareersPosition'));
const CareersPositionDetail = loadable(() => import('../Pages/CareersPositions/CareersPositionDetail'));
const CareersPositionsApply = loadable(() => import('../Pages/CareersPositions/Apply'));
const Recruitmentfraud = loadable(() => import('../Pages/Recruitmentfraud/Recruitmentfraud'));
//const Development = loadable(() => import('../Pages/Development/Development'));
// const Redevelopment = loadable(() => import('../Pages/Redevelopment/Redevelopment'));
// const Leasing = loadable(() => import('../Pages/Leasing/Leasing'));
// const Operations = loadable(() => import('../Pages/Operations/Operations'));
const Responsibility = loadable(() => import('../Pages/Responsibility/Responsibility'));
const TheEnvironment = loadable(() => import('../Pages/TheEnvironment/TheEnvironment'));
const SocialResponsibility = loadable(() => import('../Pages/SocialResponsibility/SocialResponsibility'));
const Expertise = loadable(() => import('../Pages/Expertise/Expertise'));
const CorporateGovernance = loadable(() => import('../Pages/CorporateGovernance/CorporateGovernance'));
const News = loadable(() => import('../Pages/News/News'));
const NewsDetail = loadable(() => import('../Pages/NewsDetails/NewsDetails'));
const Office = loadable(() => import('../Pages/Office/Office'));
const Collection = loadable(() => import('../Pages/Collection/Collection'));
const StyleGuide = loadable(() => import('../Pages/StyleGuide/StyleGuide'));
const Terms = loadable(() => import('../Pages/Terms/Terms'));
const Privacy = loadable(() => import('../Pages/Privacy/Privacy'));
const Covid19 = loadable(() => import('../Pages/Covid19/Covid19'));
const Accessibility = loadable(() => import('../Pages/Accessibility/Accessibility'));

class Routes extends Component {
    componentDidMount() {
        this.loadGCM();
        // Google Analytics Init
        if (navigator.userAgent.indexOf('Speed Insights') === -1) {
            const tagManagerArgs = {
                gtmId: 'GTM-M7ZQT9R',
            };
            TagManager.initialize(tagManagerArgs);
        }
    }

    loadGCM() { //Google Consent Mode
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("consent", "default", {
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          analytics_storage: "denied",
          functionality_storage: "denied",
          personalization_storage: "denied",
          security_storage: "granted",
          wait_for_update: 2000,
        });
        gtag("set", "ads_data_redaction", true);
        gtag("set", "url_passthrough", true);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { location } = this.props;
        const locationChanged = nextProps.location !== location;

        if (locationChanged) this.handleRouteChange(nextProps.location.pathname);
    }

    handleRouteChange(pathname) {
        const { content, fetchContent } = this.props;
        const currentApiRoutes = apiRoutes[pathname];

        if (navigator.userAgent.indexOf('Speed Insights') === -1) {
            window.fbq('track', 'PageView');
        }

        if (currentApiRoutes) {
            currentApiRoutes.forEach(route => {
                // Only fetch content if not already in the Redux store
                if (!content || !content[route.reducer]) fetchContent(pathname);
            });
        }
    }

    render() {
        return (
            <Switch>
                {/* HOME */}
                <Route exact path="/" component={Home} />
                {/* CASE STUDIES */}
                {/* <Route path="/development" component={props => <Development {...props} />} />
                <Route path="/redevelopment" component={props => <Redevelopment {...props} />} />
                <Route path="/leasing" component={props => <Leasing {...props} />} />
                <Route path="/operations" component={props => <Operations {...props} />} /> */}
                {/* COMPANY */}
                <Route path="/about" component={props => <About {...props} />} />
                <Route path="/awards" component={() => <Awards />} />
                <Route path="/leadership" render={() => <Placeholder text="Leadership" />} />
                <Route path="/responsibility" component={props => <Responsibility {...props} />} />
                <Route path="/expertise" component={props => <Expertise {...props} />} />

                {/* HEALTH AND EQUITABLE + REDIRECTS */}
                <Route path="/healthy-and-equitable" component={props => <SocialResponsibility {...props} />} />
                <Route
                    path="/social-responsibility"
                    render={() => {
                        return <Redirect to="/healthy-and-equitable" />;
                    }}
                />

                {/* SUSTAINABLE + REDIRECTS */}
                <Route path="/sustainable" component={props => <TheEnvironment {...props} />} />
                <Route
                    path="/protecting-the-environment"
                    render={() => {
                        return <Redirect to="/sustainable" />;
                    }}
                />
                <Route
                    path="/environmental-stewardship"
                    render={() => {
                        return <Redirect to="/sustainable" />;
                    }}
                />

                {/* GOVERNANCE + REDIRECTS */}
                <Route path="/governance" component={props => <CorporateGovernance {...props} />} />
                <Route
                    path="/corporate-governance"
                    render={() => {
                        return <Redirect to="/governance" />;
                    }}
                />

                {/* CAREERS */}
                <Route exact path="/careers" component={props => <CareersLanding {...props} />} />
                <Route exact path="/careers/positions" component={props => <CareersPosition {...props} />} />
                <Route path="/careers/positions/:slug" component={props => <CareersPositionDetail {...props} />} />
                <Route path="/apply" component={props => <CareersPositionsApply {...props} />} />
                <Route path="/careers/recruitmentfraud" component={props => <Recruitmentfraud {...props} />} />
                {/* CONTACT */}
                <Route path="/contact" component={props => <Contact {...props} />} />
                {/* LEGAL */}
                <Route path="/terms-and-conditions" component={props => <Terms {...props} />} />
                <Route path="/privacy-policy" component={props => <Privacy {...props} />} />
                {/* OFFICE (see office sub-routes in Office/Routes.js) */}
                <Route path="/office" component={props => <Office {...props} />} />
                {/* COLLECTION */}
                <Route path="/collection/:slug" component={props => <Collection {...props} />} />
                {/* STUDIO */}
                <Route path="/studio" component={props => <Studio {...props} />} />
                {/* NEWS */}
                <Route exact path="/news" component={props => <News {...props} />} />
                <Route path="/news/:cta" render={props => <NewsDetail {...props} serverURL={this.props.serverURL} />} />
                {/* COVID19 */}
                <Route path="/covid-19" component={props => <Covid19 {...props} />} />
                {/* ACCESSIBILITY */}
                <Route path="/accessibility" component={props => <Accessibility {...props} />} />
                {/* OTHER */}
                <Route path="/error-test" render={() => <Placeholder text="Error!" />} />
                <Route path="/styleguide" component={props => <StyleGuide {...props} />} />
                {/* ACCESSIBILITY */}
                <Route path="/accessibility" component={props => <Accessibility {...props} />} />
                <Route render={() => <NotFound />} />
            </Switch>
        );
    }
}

Routes.propTypes = {
    history: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    serverURL: PropTypes.string,
    fetchContent: PropTypes.func.isRequired,
};

export default withRouter(connect(({ content, errors }) => ({ content, errors }), { fetchContent })(Routes));
